import axios from "axios"

export const updatePhoto = (
  id: string,
  CompanyId: string,
  EliminatePhoto: boolean,
  Photo: any
) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: "api/User/ChangeImage/Me",
      data: { id, CompanyId, EliminatePhoto, Photo },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((response: any) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}
export default updatePhoto
