import { ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { setAuthenticated, setSelectedPage } from "../../store/root";

interface ChildMenuInterface {
  isCalendar?: boolean;
  id: string;
  name: string;
  protect: boolean;
}

export const ChildMenu = memo(
  ({ name, id, protect, isCalendar = false }: ChildMenuInterface) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedPage, menuOpened, disabledMenu } = useSelector(
      (state: RootState) => state.root
    );

    const handleCLick = useCallback(() => {
      if (!protect) dispatch(setAuthenticated(false));
      navigate(`/${id}`, {
        replace: true,
      });
      if (isCalendar) localStorage.setItem("BluBookingCalendar", id);
      dispatch(setSelectedPage(id));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id, navigate]);

    return (
      <ListItemButton
        key={id}
        selected={selectedPage === id}
        onClick={handleCLick}
        disabled={disabledMenu}
      >
        {menuOpened && <ListItemIcon />}
        <Typography
          variant="subtitle1"
          sx={{
            color: "var(--base-color)",
            fontWeight:
              selectedPage === id ? "600 !important" : "normal !important",
            fontSize: "18px !important",
            lineHeight: "20px !important",
          }}
        >
          {name}
        </Typography>
      </ListItemButton>
    );
  }
);
