import { BluDialog, BluTextField } from "@bludata/components"
import { Button, Divider, Stack } from "@mui/material"
import { FC, useEffect, useState } from "react"

const PasswordDialog: FC<{
  openPasswordDialog: boolean
  closeDialog: (oldPassword: string, newPassword: string) => void
}> = (props): JSX.Element => {
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [enablePassword, setEnablePassword] = useState(true)

  useEffect(() => {
    if (
      password === confirmPassword &&
      password.length >= 8 &&
      oldPassword &&
      confirmPassword
    ) {
      setEnablePassword(false)
    } else {
      setEnablePassword(true)
    }
  }, [confirmPassword, oldPassword, password])

  return (
    <BluDialog
      type="warning"
      confirmButton="Salva"
      controllConfirm={false}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: 500
        }
      }}
      dialogContent={
        <div style={{ display: "flex" }}>
          <Stack direction="column" flex={1} mb={1}>
            <BluTextField
              id="oldPassword"
              type="password"
              label="Password attuale*"
              fullWidth
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value)
              }}
            />

            <Divider sx={{ opacity: 0, mb: "24px!important" }} />
            <BluTextField
              id="password"
              type="password"
              label="Nuova Password*"
              fullWidth
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <Divider sx={{ opacity: 0, mb: "10px!important" }} />
            <BluTextField
              id="confermapassword"
              type="password"
              fullWidth
              value={confirmPassword}
              label="Conferma Password*"
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
            />
          </Stack>
        </div>
      }
      open={props.openPasswordDialog}
      dialogTitle={"Cambio Password"}
      draggable
      dialogActions={
        <>
          <Button
            onClick={() => {
              setOldPassword("")
              setConfirmPassword("")
              setPassword("")
              props.closeDialog("", "")
            }}
            variant="outlined"
          >
            Annulla
          </Button>
          <Button
            disabled={enablePassword}
            onClick={() => {
              props.closeDialog(oldPassword, password)
              setOldPassword("")
              setPassword("")
              setConfirmPassword("")
            }}
            autoFocus
          >
            Salva
          </Button>
        </>
      }
      zIndex={0}
    />
  )
}

export default PasswordDialog
