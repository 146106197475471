import { useEffect, useState } from "react";

const rootElement = document.body;

function ModalObserver() {
  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    //@ts-ignore
    if (window.agenda10) {
      if (isModalOpened) {
        //@ts-ignore
        window.agenda10.startMask();
      } else {
        //@ts-ignore
        window.agenda10.endMask();
      }
    }
  }, [isModalOpened]);

  useEffect(() => {
    const config = { childList: true };
    const callback = () => {
      setTimeout(() => {
        const modalWindow = rootElement.querySelector("div.MuiModal-root");
        const modalOpened = !!modalWindow;
        setIsModalOpened(modalOpened);
      }, 1);
    };
    const observer = new MutationObserver(callback);
    observer.observe(rootElement, config);
    return () => {
      observer.disconnect();
    };
  }, []);

  return <></>;
}

export default ModalObserver;
