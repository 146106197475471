import { Box, Stack, Typography } from "@mui/material";
import { Case, Default, Switch } from "react-if";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { RootState } from "../../store";
import {
  Generali,
  Layout,
  NewPointOfSales,
  NewUsers,
  Services,
  Studi,
} from "@bludata/configurazione-components";
import { ConfigurationF10Drawer } from "./components/drawer";

export const ConfigurazioneFocus10Page = () => {
  const { selectedPage } = useSelector(
    (state: RootState) => state.configurationF10
  );

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      flex="1"
      flexDirection="column"
    >
      <Stack flex="1" flexDirection="row">
        <ConfigurationF10Drawer />
        <Box sx={{ flex: 1, position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
            }}
          >
            <Switch>
              <Case condition={selectedPage === "generali"}>
                <Fragment key="generali">
                  <Generali software="f10" />
                </Fragment>
              </Case>
              <Case condition={selectedPage === "services"}>
                <Fragment key="services">
                  <Services />
                </Fragment>
              </Case>
              <Case condition={selectedPage === "studi"}>
                <Fragment key="studi">
                  <Studi />
                </Fragment>
              </Case>
              <Case condition={selectedPage === "users"}>
                <NewUsers software="f10" isFocus10 isBluBookingActive />
              </Case>
              <Case condition={selectedPage === "punti-vendita"}>
                <NewPointOfSales software="f10" isFocus10 isBluBookingActive />
              </Case>
              <Case condition={selectedPage === "shop-signs"}>
                <Fragment key="shop-sign">
                  <Layout software="f10" isConsole />
                </Fragment>
              </Case>
              <Default>
                <Stack
                  flex="1"
                  justifyContent="center"
                  sx={{ textAlign: "center" }}
                >
                  <Typography variant="h4">Pagina non disponibile</Typography>
                </Stack>
              </Default>
            </Switch>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
