import axios from "axios"
import { memo, useEffect } from "react"

export const GoogleCalendarRedirect = memo((): JSX.Element => {
  const { url } = JSON.parse(sessionStorage.getItem("sessionData") ?? "{}")

  axios.defaults.baseURL = url ? url : process.env.REACT_APP_API_URL

  const googleHref = () => {
    return new Promise<string>((resolve, reject) => {
      const urlString = window.location.href
      var url = new URL(urlString)
      axios
        .get(
          `/api/GoogleCalendarUserSync/authorize?token=${url.searchParams.get(
            "token"
          )}`
        )
        .then((res: any) => {
          resolve(res.data.data)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  }

  useEffect(() => {
    googleHref().then((res) => {
      window.location.href = res
    })
  }, [])

  return <></>
})