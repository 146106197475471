import { memo } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { TreeListItemProps } from "./interface";
import { Box, Drawer, List } from "@mui/material";
import { ParentMenu } from "./parent";

const treeItemList: TreeListItemProps[] = [
  {
    name: "config",
    title: "Configurazione",
    icon: <SettingsIcon color="primary" style={{ fontSize: "25px" }} />,
    protect: false,
    children: [
      {
        id: "generali",
        name: "Generali",
      },
      {
        id: "services",
        name: "Servizi",
      },
      {
        id: "punti-vendita",
        name: "Punti vendita",
      },
      {
        id: "studi",
        name: "Studi",
      },
      {
        id: "users",
        name: "Utenti",
      },
      {
        id: "shop-signs",
        name: "Layout",
      },
    ],
  },
];

export const ConfigurationF10Drawer = memo(() => {
  return (
    <>
      <Drawer
        open
        variant="permanent"
        sx={{
          position: "static",
          width: "250px",
          height: "100%",
          "& .MuiPaper-root": {
            position: "static",
            backgroundColor: "var(--base-foreground-color)",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box sx={{ height: "100%" }}>
          <List
            sx={{
              flex: 1,
              overflowY: "auto",
              pt: 0,
              "& .MuiListItemButton-root": {
                height: "46px",
                paddingLeft: "6px !important",
              },
              "& .MuiListItemButton-root:hover": {
                backgroundColor: "var(--hovered-background-color) !important",
                borderLeft: "6px solid var(--base-color)",
                paddingLeft: "0px !important",
              },
              "& .MuiListItemButton-root.Mui-selected": {
                backgroundColor: "var(--hovered-background-color) !important",
                borderLeft: "6px solid var(--base-color)",
                paddingLeft: "0px !important",
              },
              "& .MuiListItem-root.Mui-selected": {
                backgroundColor: "var(--base-color) !important",
              },
              "& .MuiListItem-root.Mui-selected svg": {
                color: "var(--reverse-color) !important",
              },
              "& .MuiListItem-root.Mui-selected .MuiListItemButton-root:hover":
                {
                  backgroundColor: "var(--base-color) !important",
                },
            }}
          >
            {treeItemList.map((treeItem: any) => (
              <ParentMenu {...treeItem} />
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
});
