// @ts-nocheck
import "./App.css";
import "dayjs/locale/it";
import { useSelector } from "react-redux";
import { RootState, store } from "./store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BluThemeProvider } from "@bludata/components";
import { setDisabledMenu } from "./store/root";
import { AppRoutes } from "./lib/routes";

(window as any).disableMenuBluBooking = (value: boolean) => {
  store.dispatch(setDisabledMenu(value));
};

function App() {
  const { darkMode } = useSelector((state: RootState) => state.root);

  return (
    <BluThemeProvider themeMode={darkMode}>
      <>
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
        <AppRoutes />
      </>
    </BluThemeProvider>
  );
}

export default App;
