import axios from "axios"

export const updatePassword = (
  oldPasswordValue: string,
  newPasswordValue: string
) => {
  const md5 = require("md5")
  const oldPassword: string = md5(oldPasswordValue)
  const newPassword = md5(newPasswordValue)
  return new Promise<any>((resolve, reject) => {
    axios
      .post(`api/Authenticate/change-password`, { oldPassword, newPassword })
      .then((response: any) => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.Message)
      })
  })
}
export default updatePassword
