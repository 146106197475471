import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "../lib/cookie";

export interface ConfigurationFocus10SliceState {
  accessToken: string;
  menuOpened: boolean;
  selectedPage: string;
}

const initialState: ConfigurationFocus10SliceState = {
  accessToken: getCookie("BluBookingToken") ?? "",

  menuOpened: true,
  selectedPage: localStorage.getItem("BluBookingCalendar") ?? "calendar",
};

const ConfigurationFocus10Slice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setF10AccessToken(
      state: ConfigurationFocus10SliceState,
      action: PayloadAction<string>
    ) {
      state.accessToken = action.payload;
    },

    setF10MenuOpened(
      state: ConfigurationFocus10SliceState,
      action: PayloadAction<boolean>
    ) {
      state.menuOpened = action.payload;
    },
    toggleF10MenuOpened(state: ConfigurationFocus10SliceState) {
      state.menuOpened = !state.menuOpened;
    },
    setF10SelectedPage(
      state: ConfigurationFocus10SliceState,
      action: PayloadAction<string>
    ) {
      state.selectedPage = action.payload;
    },
  },
});

export const {
  setF10AccessToken,
  setF10MenuOpened,
  toggleF10MenuOpened,
  setF10SelectedPage,
} = ConfigurationFocus10Slice.actions;

export default ConfigurationFocus10Slice.reducer;
