import axios from "axios";
import { store } from "../store";
import {
  setAccessToken,
  setIsManualAppointmentBlock,
  setIsSingleCalendarManagement,
  setLoginData,
  setRefreshToken,
  setSoftware,
  setVisibilityUserEnum,
} from "../store/root";
import { setCookie } from "../lib/cookie";

export const refreshToken = async (refreshToken: string): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await axios.post("api/Authenticate/refresh", {
      refreshToken: refreshToken,
    });

    if (data) {
      store.dispatch(setAccessToken(data.token));
      setCookie("BluBookingToken", data.token);

      store.dispatch(setRefreshToken(data.refreshToken));
      setCookie("BluBookingRefreshToken", data.refreshToken, 48);

      if (data.photo !== null) setCookie("BluBookingUserPhoto", data.photo);
      setCookie("BluBookingUserAdmin", data.isAdmin);

      if (data?.webSocketChannel)
        setCookie("BluBookingSocketChannel", data?.webSocketChannel);
      setCookie("BluBookingpointOfSaleId", data?.pointOfSaleId);
      setCookie("BluBookingSoftware", data?.software ?? 0);

      setCookie(
        "BluBookingManualAppointmentBlock",
        data?.isManualAppointmentBlock ?? false
      );
      store.dispatch(
        setIsManualAppointmentBlock(data?.isManualAppointmentBlock ?? false)
      );

      setCookie(
        "BluBookingIsSingleCalendarManagement",
        data?.isSingleCalendarManagement ?? true
      );
      store.dispatch(
        setIsSingleCalendarManagement(data?.isSingleCalendarManagement ?? true)
      );

      store.dispatch(setSoftware(data.software));
      store.dispatch(setVisibilityUserEnum(data?.visibilityUserEnum ?? 0));
      setCookie("BluBookingVisibilityUserEnum", data?.visibilityUserEnum ?? 0);
      store.dispatch(
        setLoginData({
          photo: data.photo,
          isAdmin: data.isAdmin,
          username: data.username,
          pointOfSaleId: data?.pointOfSaleId,
          socketChannel: data?.webSocketChannel,
        })
      );
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
