import { FC, memo, useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";

interface BlinkBadgeInterface {
  value: number;
}

export const BlinkBadge: FC<BlinkBadgeInterface> = memo(({ value }) => {
  const [bagdeValue, setBadgeValue] = useState<number>(value);

  useEffect(() => {
    const timeout = setInterval(() => {
      setBadgeValue(bagdeValue > 0 ? 0 : value);
    }, 700);

    return () => {
      clearInterval(timeout);
    };
  });

  return (
    <Badge badgeContent={bagdeValue} color="error">
      <NotificationsIcon sx={{ paddingLeft: "4px" }} />
    </Badge>
  );
});
