import { Link, Stack, Toolbar, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import BluAlert from "../../UI/components/BluAlert";
import "./footer.css";
import configurazione_components from "@bludata/configurazione-components/package.json";
import calendar from "@bludata/calendar/package.json";
import components from "@bludata/components/package.json";

const AppFooter = () => {
  const [version, setVersion] = useState<any | ReactElement>(null);
  return (
    <Toolbar
      variant="dense"
      sx={{
        borderTop: "1px solid var(--divider-color)",
        backgroundColor: "var(--base-foreground-color)",
      }}
    >
      <BluAlert
        type="info"
        show={!!version}
        text={version}
        title={"Versioni"}
        handleClose={() => {
          setVersion(null);
        }}
      />
      <Stack flexDirection="row" sx={{ width: "100%" }} alignItems="center">
        <Typography variant="body1" sx={{ flex: 1 }}>
          <Link
            underline="none"
            sx={{ color: "var(--color) !important", fontWeight: "400" }}
            onClick={(e) => {
              if (e.altKey) {
                setVersion(
                  <>
                    <Typography sx={{ mb: 1 }}>
                      console-appuntamenti-online:
                      {process.env.REACT_APP_VERSION}
                    </Typography>
                    <Typography>
                      @bludata/configurazione-components:
                      {configurazione_components.version}
                    </Typography>
                    <Typography>
                      @bludata/calendar: {calendar.version}
                    </Typography>
                    <Typography>
                      @bludata/components: {components.version}
                    </Typography>
                  </>
                );
              }
            }}
          >
            Versione: {process.env.REACT_APP_VERSION}
          </Link>
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginRight: "10px", color: "var(--color)" }}
        >
          &#169; {new Date().getFullYear()} Bludata Informatica S.r.l.
        </Typography>
        <img
          onClick={() => {
            window.open("https://www.bludata.com");
          }}
          style={{ height: "26px", cursor: "pointer", width: "80px" }}
          src="https://focus.bludata.com/logo_bludata_50.png"
          alt="Logo Bludata Informatica S.r.l."
        />
      </Stack>
    </Toolbar>
  );
};

export default AppFooter;
