import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginForm } from "../feature/login";
import { getCookie } from "../lib/cookie";

export interface RootSliceState {
  accessToken: string;
  refreshToken: string;
  menuOpened: boolean;
  selectedPage: string;
  loginData?: LoginForm;
  darkMode: "light" | "dark";
  refetch: boolean;
  openPasswordPopUp: boolean;
  treeItemList: {
    [index: string]: boolean;
  };
  isManualAppointmentBlock: boolean;
  disabledMenu: boolean;
  isBluBookingEnabled: boolean;
  sessionExpired: boolean;
  isAdmin: boolean;
  pointOfSaleId?: string;
  username?: string;
  photo?: string | null;
  focus10SelectedView: string | null;
  isAuthenticated: boolean;
  socketChannel?: string;
  software?: number;
  companyColor?: number;
  isSingleCalendarManagement: boolean;
  visibilityUserEnum: number;
}

const initialState: RootSliceState = {
  accessToken: getCookie("BluBookingToken") ?? "",
  refreshToken: getCookie("BluBookingRefreshToken") ?? "",
  menuOpened: true,
  selectedPage: localStorage.getItem("BluBookingCalendar") ?? "calendar",
  darkMode: "light",
  refetch: false,
  openPasswordPopUp: false,
  isManualAppointmentBlock:
    (getCookie("BluBookingIsManualAppointmentBlock") ?? "false") === "true",
  treeItemList: {
    calendar: true,
    config: false,
  },
  disabledMenu: false,
  isBluBookingEnabled: false,
  sessionExpired: false,
  isAuthenticated: false,
  isAdmin: (getCookie("BluBookingUserAdmin") ?? "false") === "true",
  pointOfSaleId: getCookie("BluBookingpointOfSaleId") ?? undefined,
  username: localStorage.getItem("BluBookingUsername") ?? undefined,
  photo: getCookie("BluBookingUserPhoto") ?? undefined,
  focus10SelectedView: null,
  socketChannel: getCookie("BluBookingSocketChannel") ?? undefined,
  software: parseInt(getCookie("BluBookingSoftware") ?? "0"),
  companyColor: parseInt(getCookie("BluBookingCompanyColor") ?? "0"),
  isSingleCalendarManagement:
    (getCookie("BluBookingIsSingleCalendarManagement") ?? "true") === "true",
  visibilityUserEnum: parseInt(
    getCookie("BluBookingVisibilityUserEnum") ?? "0"
  ),
  // focus10SelectedView: "Calendario appuntamenti",
};

const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setAccessToken(state: RootSliceState, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setSoftware(state: RootSliceState, action: PayloadAction<number>) {
      state.software = action.payload;
    },
    setVisibilityUserEnum(
      state: RootSliceState,
      action: PayloadAction<number>
    ) {
      state.visibilityUserEnum = action.payload;
    },
    setCompanyColor(state: RootSliceState, action: PayloadAction<number>) {
      state.companyColor = action.payload;
    },
    setRefreshToken(state: RootSliceState, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    setMenuOpened(state: RootSliceState, action: PayloadAction<boolean>) {
      state.menuOpened = action.payload;
    },
    toggleMenuOpened(state: RootSliceState) {
      state.menuOpened = !state.menuOpened;
    },

    setAuthenticated(state: RootSliceState, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setLoginData(
      state: RootSliceState,
      action: PayloadAction<{
        isAdmin: boolean;
        pointOfSaleId?: string;
        username?: string;
        photo?: string;
        socketChannel?: string;
      }>
    ) {
      return { ...state, ...action.payload };
    },
    setPhoto(state: RootSliceState, action: PayloadAction<string | null>) {
      state.photo = action.payload;
    },
    setSelectedPage(state: RootSliceState, action: PayloadAction<string>) {
      state.selectedPage = action.payload;
    },
    resetRootDefault(state: RootSliceState) {
      state.accessToken = initialState.accessToken;
      state.selectedPage = initialState.selectedPage;
      state.loginData = initialState.loginData;
      state.darkMode = initialState.darkMode;
    },
    toggleDarkMode(state: RootSliceState) {
      state.darkMode = state.darkMode === "light" ? "dark" : "light";
    },
    setRefetch(state: RootSliceState) {
      state.refetch = !state.refetch;
    },
    setTreOpen(state: RootSliceState, action: PayloadAction<string>) {
      state.treeItemList[action.payload] = !state.treeItemList[action.payload];
    },
    setOpenPasswordPopUp(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.openPasswordPopUp = action.payload;
    },
    setDisabledMenu(state: RootSliceState, action: PayloadAction<boolean>) {
      state.disabledMenu = action.payload;
    },
    setIsBluBookingEnabled(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.isBluBookingEnabled = action.payload;
    },
    setSessionExpired(state: RootSliceState, action: PayloadAction<boolean>) {
      state.sessionExpired = action.payload;
    },
    setIsAdmin(state: RootSliceState, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    setPointOfSaleId(state: RootSliceState, action: PayloadAction<string>) {
      state.pointOfSaleId = action.payload;
    },
    setFocus10SelectedView(
      state: RootSliceState,
      action: PayloadAction<string | null>
    ) {
      state.focus10SelectedView = action.payload;
    },
    setIsManualAppointmentBlock(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.isManualAppointmentBlock = action.payload;
    },
    setIsSingleCalendarManagement(
      state: RootSliceState,
      action: PayloadAction<boolean>
    ) {
      state.isSingleCalendarManagement = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setVisibilityUserEnum,
  setRefreshToken,
  setMenuOpened,
  toggleMenuOpened,
  setLoginData,
  setSelectedPage,
  resetRootDefault,
  toggleDarkMode,
  setRefetch,
  setTreOpen,
  setOpenPasswordPopUp,
  setDisabledMenu,
  setIsBluBookingEnabled,
  setSessionExpired,
  setIsAdmin,
  setPointOfSaleId,
  setFocus10SelectedView,
  setAuthenticated,
  setSoftware,
  setCompanyColor,
  setPhoto,
  setIsManualAppointmentBlock,
  setIsSingleCalendarManagement,
} = RootSlice.actions;

export default RootSlice.reducer;
