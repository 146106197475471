import {
  Collapse,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { memo, useMemo } from "react";
import { TreeListItemProps } from "./interface";
import { ChildMenu } from "./child";

export const ParentMenu = memo(
  ({ name, title, icon, protect, children }: TreeListItemProps) => {
    const Icon = useMemo(() => {
      return (
        <ListItemIcon
          sx={{
            minWidth: "fit-content",
            mr: "15px",
            ml: "0px",
          }}
        >
          {icon}
        </ListItemIcon>
      );
    }, [icon]);

    const Text = useMemo(() => {
      return (
        <Typography
          variant="subtitle1"
          sx={{
            color: "var(--base-color)",
            fontWeight: "normal !important",
            fontSize: "18px !important",
            lineHeight: "20px !important",
          }}
        >
          {title}
        </Typography>
      );
    }, [title]);

    return (
      <ParentWrapper
        treeOpen
        treeItems={children}
        protect={protect}
        title={title}
        disabled={false}
      >
        <>
          <ListItem disablePadding selected={false}>
            <Stack style={{ padding: "7px 15px" }} direction="row" flex="1">
              {Icon}
              <Stack
                flex="1"
                justifyItems="center"
                sx={{ height: "100%", margin: "auto" }}
              >
                {Text}
              </Stack>
            </Stack>
          </ListItem>
        </>
      </ParentWrapper>
    );
  }
);

interface ParentWrapperInterface {
  treeOpen: boolean;
  treeItems: any;
  children: JSX.Element;
  disabled: boolean;
  protect: boolean;
  title: string;
}

const ParentWrapper = memo(
  ({
    treeOpen,
    children,
    treeItems,
    disabled,
    protect,
    title,
  }: ParentWrapperInterface) => {
    const GetTreItems = useMemo(() => {
      return (
        <>
          {treeItems.map((item: any) => (
            <ChildMenu {...item} protect={protect} />
          ))}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeItems]);

    return (
      <>
        {children}
        <Collapse in={treeOpen} timeout={"auto"} unmountOnExit>
          {GetTreItems}
        </Collapse>
      </>
    );
  }
);
