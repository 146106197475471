export const content = `
### DEFINIZIONI
I seguenti termini hanno il significato appresso indicato: &nbsp;   
**_“Bludata”_** : Bludata Informatica S.r.l., con sede in Breda di Piave, Via delle Industrie, 10 C.F./P. IVA/Reg. Imprese TV 03351040260 R.E.A. CCIAA TV 265422 C.S. 100.000 i.v. PEC bludata@legalmail.it &nbsp;    
**_“Licenziatario”_** : il soggetto indentificato nel Modulo d’ordine. &nbsp;   
**_“Parti”_** : Bludata e il Licenziatario. &nbsp;   
**_“Modulo d’ordine”_** : il documento con cui si richiede l’attivazione del Software e si accettano le condizioni previste in tale documento, nel presente Contratto e nei suoi Allegati.  &nbsp;    
**_“Contratto di Licenza”_** : è costituito dalle presenti Condizioni generali di contratto, dal Modulo d’ordine e dagli Allegati. &nbsp;     
**_“Documentazione”_** : copia della documentazione del Software, fornita da Bludata insieme al Software per l’utilizzo del medesimo. &nbsp;     
**_“Allegati”_** : i seguenti allegati al presente Contratto: Documentazione, Atto di Nomina del Responsabile del Trattamento dei Dati Personali. &nbsp;     
**_“Attività di Supporto”_** : fornitura in via telematica dei servizi di supporto, installazione, assistenza e manutenzione del Software, nonché la fornitura in via telematica di eventuali aggiornamenti del Software. &nbsp;     
**_“Software”_** : Blu Booking, software per la prenotazione online degli appuntamenti integrato con il software gestionale FOCUS, meglio descritto nella Documentazione. &nbsp;   


### PREMESSO CHE:  
* Bludata ha fornito al Licenziatario in regime di licenza il sistema gestionale denominato FOCUS 10 nella titolarità di Bludata;  
* Bludata ha sviluppato Blu Booking, un’applicazione web su cloud per la prenotazione online degli appuntamenti, integrata con il software gestionale FOCUS;  
* il Licenziatario è interessato ad ottenere in licenza il Software (come definito sopra);  
* con il presente Contratto di Licenza le Parti intendono regolamentare la concessione in licenza da parte di Bludata al Licenziatario dei diritti di utilizzo del Software, come di seguito specificato.  

### TUTTO CIÒ PREMESSO, le Parti convengono quanto segue.
1.  **OGGETTO DELLA LICENZA**  
    1. Le premesse, il Modulo d’Ordine e gli Allegati costituiscono parte integrante del Contratto di Licenza.  
    2. Ai termini e alle condizioni di cui al presente Contratto di Licenza, Bludata concede al Licenziatario una licenza non-esclusiva, a titolo oneroso, limitata alle sedi del Licenziatario per l’utilizzo del Software nell’ambito dell’attività del Licenziatario, con esclusione del diritto di concedere il Software in sub-licenza a terzi.   

2.  **LIMITAZIONI AL DIRITTO D’USO**   
    1. Salvo quanto espressamente consentito dalla legge, il Licenziatario non è autorizzato alla riproduzione in copie (fatta salva la facoltà prevista dall’articolo 64-ter Legge 633/1941 di effettuare una copia di back-up alle condizioni e con i limiti ivi indicati), alla modifica, al reverse engeneering, alla traduzione, adattamento, trasformazione, decompilazione e disassemblaggio del Software, nonché alla riproduzione del medesimo. In nessun caso è consentito al Licenziatario vendere, dare in licenza, offrire in leasing o locazione, cedere, trasmettere e/o distribuire a terzi il Software in qualsiasi maniera o altrimenti disporre del Software, salvo quanto espressamente consentito in base al presente Contratto di Licenza. Al Licenziatario è espressamente vietato effettuare l’adattamento, la modifica, la revisione, il miglioramento, l’up-grade e la creazione di lavori e/o opere derivate del Software o basate sullo stesso e/o sulla Documentazione per qualsiasi fine, inclusa la correzione di errori o ogni altro genere di attività di manutenzione e supporto.  
    2. È espressamente escluso qualsiasi ulteriore utilizzo del Software e/o della Documentazione da parte del Licenziatario.   
    3. Bludata si riserva tutti i diritti sul Software non espressamente ceduti al Licenziatario.  

3.  **CORRISPETTIVO E TERMINI DI PAGAMENTO**   
    1. Il Licenziatario si impegna a corrispondere a Bludata, a fronte della concessione dei diritti d’uso inerenti il Software ai sensi del presente Contratto di Licenza, un canone annuale pari all’importo indicato nella conferma d’ordine.  
    2. Il pagamento del corrispettivo dovrà essere effettuato dal Licenziatario alle scadenze indicate da Bludata presso le coordinate bancarie di Bludata, che saranno da questa comunicate al Licenziatario.   
    3. In caso di ritardato pagamento del canone da parte del Licenziatario, Bludata avrà diritto agli interessi di mora che saranno calcolati nella misura di legge a decorrere dal giorno successivo alla scadenza del termine di pagamento.  
    4. Qualora il Licenziatario ometta di effettuare il pagamento entro il termine convenuto e il pagamento venga sospeso o irragionevolmente negato a seguito di formale messa in mora da parte di Bludata, quest’ultima avrà diritto di risolvere il presente Contratto di Licenza ai sensi dell’art. 11.2 che segue.  
    5. Il Canone annuale sarà automaticamente soggetto a revisione annua, in relazione alla variazione in aumento dell’indice ISTAT dei prezzi al consumo per le famiglie di operai ed impiegati (FOI), ed adeguato allo stesso incremento.  

4.  **OBBLIGHI DI SEGRETEZZA**  
    1. Il Licenziatario riconosce e accetta che il materiale, il know-how tecnico (inclusa la Documentazione) e qualsiasi ulteriore informazione relativa al Software contengono dati di natura riservata di proprietà di Bludata, messi a disposizione del Licenziatario esclusivamente per l’utilizzo nei limiti di cui al presente Contratto di Licenza. Il Licenziatario non è autorizzato, nel corso della durata del presente Contratto di Licenza o successivamente alla sua scadenza o risoluzione anticipata per qualsiasi causa, a comunicare e/o rendere disponibile a terzi il materiale e/o le informazioni relative al Software, sia direttamente che indirettamente senza il preventivo consenso scritto di Bludata. Il Licenziatario è tenuto a indennizzare e manlevare Bludata per mancati guadagni, costi, danni e spese derivanti o connesse all’inadempimento del Licenziatario agli obblighi derivanti dal presente art. 4.   
    2. Salvo il preventivo consenso scritto reso dall’altra Parte, ciascuna Parte si impegna altresì, per tutta la durata del presente Contratto di Licenza e successivamente alla sua cessazione per qualsivoglia ragione, a mantenere riservate le Informazioni Riservate relative all’altra Parte, ad utilizzarle per i soli fini di cui al presente Contratto di Licenza e a non renderle note a terzi o altrimenti comunicarle. Per "Informazioni Riservate" s’intende, a titolo meramente esemplificativo, qualsiasi trovato (brevettato o brevettabile), know-how e, in genere, qualsivoglia informazione tutelata o meno da diritto di proprietà industriale o intellettuale, ogni informazione sui prodotti di ciascuna Parte, nonché qualsiasi documento ivi inclusa la Documentazione, materiale, idea, dato o altra informazione relativa al Software e/o a Bludata o al Licenziatario, alle rispettive attività di ricerca e sviluppo, o ai relativi rapporti commerciali, a nominativi di clienti, fornitori, appaltatori o partner commerciali e ogni altro documento e informazione di natura tecnica, economica, commerciale, finanziaria o amministrativa, redatto o comunicato dall’una o dall’altra parte, sia oralmente che per iscritto, su supporto cartaceo o elettronico, a prescindere dal fatto che tali informazioni siano o meno state identificate da una Parte quali riservate.
    3. Le "Informazioni Riservate" dell’altra parte non includono, comunque, documenti, materiale, dati, o altre informazioni che:
        - sono già note al pubblico al momento della comunicazione alla parte che le riceva;  
        - divengano disponibili/note al pubblico, ad esclusione dei casi in cui tale notorietà si verifichi in seguito alla comunicazione effettuata da una Parte in violazione dei termini di cui al presente articolo;  
        - al momento della comunicazione siano già note alla parte che le riceva, sempre che tale conoscenza non sia stata ottenuta in maniera fraudolenta e la parte sia in grado di darne prova;  
        - al momento della comunicazione siano già note alla parte che le riceva, essendole state trasmesse in precedenza da un terzo legittimato non soggetto ad un obbligo di riservatezza;  
        - siano state elaborate in modo indipendente da una parte con mezzi e modalità non fraudolenti;  
        - debbano essere comunicate in forza di disposizioni di legge, per ordine giudiziale o di pubblica autorità, o altra autorità competente;  
        - la cui divulgazione sia stata previamente autorizzata per iscritto dalla Parte che le ha trasmesse.
        
5.  **RICONOSCIMENTO DELLA TITOLARITÀ**  
    1. Il Licenziatario riconosce che Bludata è titolare dei diritti di proprietà intellettuale (incluso il diritto d’autore) sul Software e sulla documentazione d’uso e know-how tecnico ad esso afferente. Il Licenziatario riconosce che tutti i diritti di proprietà industriale e intellettuale di Bludata rimarranno in capo a quest’ultima per tutta la durata del presente Contratto di Licenza.  

6.  **GARANZIA**   
    1. Bludata dichiara e garantisce che il Software è conforme in tutti i suoi aspetti alle specifiche funzionali descritte nella Documentazione relativa a quella release del Software. Tuttavia, la descrizione delle funzioni del Software presente nella Documentazione è suscettibile di variazioni qualora il Software venga integrato con funzionalità aggiuntive da Bludata. La presente garanzia non implica la garanzia circa la ininterrotta operatività del Software o l’assenza di errori.  
    2. La garanzia di cui all’art. 6.1 non si estende a malfunzionamenti derivanti dall’uso improprio del Software, da cause esterne non imputabili direttamente a Bludata, da interventi (inclusa l’esecuzione di attività di supporto e manutenzione) o modifiche realizzate da soggetti diversi da Bludata, o dall’operatività del Software effettuata fuori dai parametri o ambienti consentiti, che con la sottoscrizione del presente Contratto di Licenza il Licenziatario dichiara di ben conoscere. Bludata non sarà responsabile dell’operatività del Software in ambiente diverso da quello indicato nella Documentazione e in relazione all’ambiente operativo indicato per ciascuna versione del Software che con la sottoscrizione del presente Contratto di Licenza il Licenziatario dichiara di ben conoscere. Non sono considerate violazioni della presente garanzia minimi scostamenti di marginale importanza per l’utilizzo prestabilito del Software, che comportano trascurabili inconvenienti per il Licenziatario.  
    3. Ove le funzionalità del Software o di parti di esso fossero interrotte, Bludata si adopererà per ripristinare il Software via web.   
    4. Bludata s’impegna inoltre a prestare al Licenziatario un servizio di assistenza telefonica, attivo dalle ore 9.00 alle ore 12.00 e dalle ore 15.00 alle ore 18.30 di ciascun giorno lavorativo, esclusi i giorni festivi, di festività nazionale ed i giorni di chiusura periodica di Bludata.  
    5. La garanzia di cui all’art. 6.1 è la sola garanzia resa da Bludata relativamente al Software e al presente Contratto di Licenza e fatto salvo quanto espressamente indicato nel Contratto di Licenza e nelle Attività di Supporto, qualsiasi altra condizione, garanzia (espressa, implicita, prevista per legge o altro) e dichiarazione relativa al rendimento, alla qualità, adeguatezza o idoneità all’uso del Software (o al raggiungimento o perseguimento di qualsiasi specifico risultato) è esclusa dal presente Contratto di Licenza.  

7.  **VIOLAZIONE DI DIRITTI DI PROPRIETÀ INTELLETTUALE**   
    1. Il Licenziatario si impegna ad informare tempestivamente Bludata di qualsiasi iniziativa giudiziale o stragiudiziale intimata o iniziata da terzi nei confronti del Licenziatario in ragione di violazioni di diritti di proprietà intellettuale di terzi derivanti dal Software.  
    2. Nell’ipotesi di controversie ai sensi dell’art. 7.1 che precede, o qualora Bludata ritenga possibile l’insorgere di controversie, il Licenziatario riconosce ed accetta che Bludata avrà facoltà di optare per uno dei seguenti rimedi: (i) apportare modifiche al Software (in modo da tale da non alterarne in maniera significativa le funzionalità); e/o (ii) sostituire il Software con una versione non interferente con diritti di terzi ma funzionalmente analoga; e/o (iii) ottenere una licenza in favore del Licenziatario che consenta di continuare ad usare il Software; o (iv) in subordine, risolvere il presente Contratto di Licenza. Il Licenziatario rinuncia sin d’ora a qualsivoglia pretesa a qualunque titolo o per qualsivoglia ragione e/o causa qualora Bludata eserciti detta facoltà.   
    3. Resta inteso che non sono attribuiti ulteriori diritti e facoltà al Licenziatario in caso di controversie ai sensi dell’art. 7.1 che precede. In ogni caso, Bludata non sarà tenuta a rispondere in caso di contestazioni di terzi che derivino da, o siano connesse a:  
        - l’utilizzo combinato del Software insieme ad altro software diverso dall’ultima release di FOCUS 10, qualora la violazione non si sarebbe verificata se non ci fosse stato tale uso combinato;  
        - modifiche al Software da parte del Licenziatario o di terzi, nell’ipotesi in cui senza le modifiche, tale violazione non si sarebbe verificata;  
        - utilizzi del Software con modalità non conformi a quelle previste dal presente Contratto di Licenza;  
        - mancato utilizzo dell’ultima versione di aggiornamento del Software, una volta che detto aggiornamento sia stato messo a disposizione da Bludata al Licenziatario.  

8. **LIMITAZIONI DI RESPONSABILITÀ**  
    1. Fatta eccezione per i casi di dolo o colpa grave, Bludata non sarà tenuta al risarcimento di alcun danno emergente o lucro cessante (incluse spese e passività) comunque patito dal Licenziatario o da terzi in dipendenza dell’uso del Software, inclusi a titolo esemplificativo i costi patiti a causa del ritardo, dell’interruzione dell’attività commerciale, della perdita d’uso, dell’impossibilità di accesso ai servizi, nonché i costi relativi alla perdita o al danneggiamento di dati.  

9. **AGGIORNAMENTI, SUPPORTO E MANUTENZIONE**
    1. Il presente Contratto di Licenza conferisce al Licenziatario il diritto a nuove release, modifiche, integrazioni o aggiornamenti del Software, o alla prestazione di servizi di supporto relativi al medesimo. Resta comunque inteso che ciò non comporta l’obbligo per Bludata di sviluppare aggiornamenti o nuove release del Software.  

10. **TERMINI DI UTILIZZO DEL SOFTWARE**  
    1. Il Licenziatario s’impegna a conservare con la massima riservatezza i codici d’accesso al Software, ed è responsabile della custodia degli stessi. Il Licenziatario s’impegna altresì a comunicare immediatamente a Bludata l’eventuale furto o smarrimento o appropriazione da parte di terzi non autorizzati dei suddetti codici d’accesso.  
    2. Il Licenziatario accetta e riconosce di essere l’unico responsabile di qualsiasi danno arrecato dall’utilizzo dei codici di accesso da parte di terzi non autorizzati, e s’impegna a manlevare e tenere indenne Bludata da ogni pretesa di detti terzi in caso di accessi non autorizzati al Software.  
    3. Bludata tratterà i dati necessari per il funzionamento del Software solamente in qualità di Responsabile esterno al trattamento dati. A tal fine, il Licenziatario nomina Bludata responsabile del trattamento, in virtù dell’atto di nomina allegato al presente contratto quale Allegato. Il Licenziatario potrà utilizzare propri responsabili, sia interni che esterni, oltre che propri incaricati del trattamento, che agiscono nell'ambito della struttura operativa del Licenziatario.   
    4. L’incarico di Bludata avrà durata pari al presente contratto, e si intenderà automaticamente revocata all’atto della cessazione dell’efficacia del presente contratto per qualsivoglia ragione.   
    5. È espressamente vietato l’utilizzo del Software per l’invio di messaggi recanti contenuto: osceno, offensivo, con scopi contrari alla morale, all’ordine pubblico, lesivo di diritti di terzi, tutelato dal diritto d’autore, o comunque in violazione delle normative vigenti, incitante alla violenza o all’odio, potenzialmente dannoso per i dispositivi dei destinatari (i.e. malware, virus, trojan) o ogni altro tipo di contenuto pregiudizievole per i destinatari.  
    6. Il Licenziatario pertanto s’impegna a tenere integralmente indenne e manlevare Bludata qualora quest’ultima sia richiesta o tenuta, direttamente o in solido, a corrispondere somme a titolo risarcitorio, di indennizzo, e sanzioni di qualsivoglia natura, in relazione al contenuto delle informazioni trasmesse tramite il Software e/o in relazione al trattamento dei dati personali.  
    7. In caso di violazione dei presenti termini di utilizzo, Bludata si riserva il diritto di bloccare con effetto immediato e/o definitivo l’accesso e l’utilizzo del Licenziatario del Software.  
    8. Il Licenziatario s’impegna inoltre a fare utilizzo del Software in conformità alla normativa vigente in materia di attività promozionale e di contrasto allo spam, ed in particolare ai Provvedimenti 4 luglio 2013 e 15 maggio 2013 del Garante per la Protezione dei Dati Personali.  
    9. Il Licenziatario riconosce ed accetta altresì che i dati raccolti mediante il Software, potranno essere trattati da Bludata in forma aggregata anche con riferimento al/i punto/i vendita del Licenziatario.  

11. **DURATA**
    1. Il presente Contratto di Licenza decorrerà dalla data di sottoscrizione del Modulo d’Ordine per un periodo di 12 mesi. Qualora tre (3) mesi prima del contratto ciascuna parte non invii formale disdetta prima della naturale scadenza del contratto a mezzo raccomandata a.r. o posta elettronica certificata, questo si intenderà rinnovato per un periodo di ulteriori dodici (12) mesi, e così via, fatta salva la risoluzione anticipata da parte di Bludata, nei casi di cui all’art. 2..  
    2. Bludata potrà ritenere automaticamente risolto il presente Contratto di Licenza, con efficacia immediata, ex articolo 1456 codice civile nel caso di inadempimento da parte del Licenziatario degli obblighi di cui alle seguenti clausole:
        - art. 2 (limitazione al diritto d’uso del Software);  
        - art. 5 (obblighi di segretezza);  
        - art. 6 (mancato pagamento o sospensione del pagamento dei corrispettivi);  
        - art. 11 (termini di utilizzo del Software);  
        - art. 12.1 (cessione del contratto e dei diritti da esso derivanti). &nbsp;     
Bludata, inoltre, potrà recedere anticipatamente dal Contratto di Licenza qualora il Licenziatario sia sottoposto ad una procedura concorsuale, sia dichiarato insolvente, o venga assoggettato a liquidazione.
    3. Inoltre, il presente Contratto di Licenza cesserà automaticamente e in maniera immediata la propria efficacia qualora dovesse cessare per qualsivoglia ragione la licenza d’uso del programma FOCUS 10 concessa al Licenziatario ovvero qualora per qualsiasi ragione il Licenziatario non dovesse sottoscrivere e/o rinnovare per qualsivoglia ragione il servizio di assistenza e manutenzione del programma FOCUS 10.  
    4. A fronte della risoluzione o recesso anticipato da parte di Bludata e/o della cessazione anticipata dell’efficacia del presente Contratto, tutti i diritti concessi al Licenziatario torneranno nella piena ed esclusiva disponibilità di Bludata. In tale circostanza il Licenziatario dovrà immediatamente cessare l’utilizzo del Software o di sue parti, nonché interrompere qualsiasi uso della Documentazione. Inoltre, il Licenziatario dovrà, entro cinque (5) giorni lavorativi dalla risoluzione, restituire o distruggere tutte le versioni del Software e dei relativi supporti anche informatici e della Documentazione e qualsiasi altra documentazione e/o materiale ad esso connessa e detenuta dallo stesso e/o dai Consulenti. Il Licenziatario è tenuto a dare conferma per iscritto a Bludata dell’avvenuta restituzione o distruzione del Software e della Documentazione.

12. **MODIFICA MODALITA’ DISDETTA SERVIZIO DI ASSISTENZA E MANUTENZIONE DEL SOFTWARE FOCUS 10**   
    1. Il servizio di assistenza e manutenzione del software FOCUS 10 sarà automaticamente rinnovato alle condizioni concordate con Bludata qualora una delle parti non invii formale disdetta almeno un (1) mese prima della naturale scadenza del contratto a mezzo posta elettronica certificata o raccomandata a.r.   

13. **VARIE**  
    1. È fatto espresso divieto alle Parti di cedere a terzi il presente Contratto di Licenza e/o i diritti derivanti dallo stesso, in qualsiasi forma ed anche parzialmente, senza il preventivo consenso scritto dell’altra Parte.  
    2. Tutte le comunicazioni tra le Parti relative al presente Contratto di Licenza dovranno essere effettuate in forma scritta e dovranno essere inviate o consegnate tramite raccomandata a.r., fax, e-mail o altro mezzo di comunicazione che garantisca la certezza dell’avvenuta ricezione. Ciascuna delle Parti potrà di volta in volta variare l’indirizzo per le comunicazioni relative al presente Contratto di Licenza dandone comunicazione scritta all’altra Parte.  
    3. Il presente Contratto di Licenza annulla e sostituisce qualsivoglia precedente accordo verbale o scritto intervenuto tra le Parti e costituisce la totalità delle intese tra le Parti relativamente all’oggetto del medesimo. Qualsiasi modifica alle condizioni di cui al presente Contratto di Licenza e ai documenti ad esso allegati non avrà alcun valore e, dunque, non sarà opponibile se non risultante da apposita scrittura integrativa, debitamente sottoscritta dai legali rappresentanti delle Parti.   
    4. Per tutto quanto non disciplinato dal presente Contratto di Licenza, si rimanda alle disposizioni di legge applicabili.
    
14. **LEGGE APPLICABILE E FORO ESCLUSIVAMENTE COMPETENTE**  
    1. Il presente Contratto è disciplinato dalla legge italiana.  
    2. Qualsiasi controversia relativa al presente Contratto di Licenza sarà devoluta alla competenza esclusiva di un Collegio Arbitrale in Treviso, mediante arbitrato vincolante, che si terrà in lingua italiana, in conformità al regolamento di Curia Mercatorum di Treviso in vigore al momento dell’instaurazione dell’arbitrato. La decisione dell’arbitro sarà inappellabile e vincolante per le parti e potrà essere fatta eseguire in ogni ordinamento giuridico dall’autorità giudiziaria competente. Ogni parte sopporterà le proprie spese legali e gli altri costi derivanti dal procedimento arbitrale. Indipendentemente da quanto precede, Bludata potrà rivolgersi all’autorità giudiziaria ordinaria competente secondo il relativo ordinamento giuridico per i procedimenti di recupero dei crediti per mancato pagamento delle fatture emesse in riferimento alla licenza del Software.

`;
