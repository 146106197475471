import { ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setF10SelectedPage } from "../../../../store/configuration-focus10";

interface ChildMenuInterface {
  isCalendar?: boolean;
  id: string;
  name: string;
  protect: boolean;
}

export const ChildMenu = memo(({ name, id }: ChildMenuInterface) => {
  const dispatch = useDispatch();
  const { selectedPage, menuOpened } = useSelector(
    (state: RootState) => state.configurationF10
  );

  const handleCLick = useCallback(() => {
    dispatch(setF10SelectedPage(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <ListItemButton
      key={id}
      selected={selectedPage === id}
      onClick={handleCLick}
    >
      {menuOpened && <ListItemIcon />}
      <Typography
        variant="subtitle1"
        sx={{
          color: "var(--base-color)",
          fontWeight:
            selectedPage === id ? "600 !important" : "normal !important",
          fontSize: "18px !important",
          lineHeight: "20px !important",
        }}
      >
        {name}
      </Typography>
    </ListItemButton>
  );
});
