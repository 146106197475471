import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const getNotifiche = async (): Promise<any[]> => {
  try {
    const {
      data: { data },
    } = await axios.get("api/Appointment/Notification");

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const useNotifiche = (key: string = "") => {
  return useQuery({
    queryFn: async () => await getNotifiche(),
    queryKey: ["notify", key],
    retry: 2,
    retryDelay: 180000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    // select: normalize,
  });
};
