import { store } from "../../store";
import { setSessionExpired } from "../../store/root";
import { refreshToken } from "../../api/refresh-token";
import axios from "axios";
import { getCookie } from "../cookie";

let requestInterceptorId: number | undefined;
let responseInterceptorId: number | undefined;

export const setRequestInterceptorId = (requestId: number) => {
  requestInterceptorId = requestId;
};
export const getRequestInterceptorId = () => requestInterceptorId;
export const setResponseInterceptorId = (responseId: number) => {
  responseInterceptorId = responseId;
};
export const getResponseInterceptorId = () => responseInterceptorId;

export const requestInterceptor = (config: any) => {
  const accessToken = getCookie("BluBookingToken");
  // const {
  //   root: { accessToken },
  // } = store.getState();

  if (accessToken !== undefined && config.headers !== undefined) {
    // Add Auth token
    config.headers.authorization = `Bearer ${accessToken}`;
  } else config.headers.authorization = undefined;

  return config;
};

export const requestErrorInterceptor = (error: any) => {
  return Promise.reject(error);
};

export const responseInterceptor = (response: any) => {
  return response;
};

export const responseErrorInterceptor = async (error: any) => {
  if (error.response) {
    if (error.response.status === 401) {
      const originalConfig = error.config;
      if (!originalConfig._retry) {
        const rf = getCookie("BluBookingRefreshToken");
        if (rf !== undefined && rf !== "") {
          originalConfig._retry = true;
          const result = await refreshToken(rf);

          if (result) return axios(originalConfig);
          // return Promise.reject(error);
        }
      }
      store.dispatch(setSessionExpired(true));
    }
  }

  return Promise.reject(error);
};
