import "./google-calendar-sync.css";

import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridFooterContainer,
  GridOverlay,
  GridSelectionModel,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import { BluLoader } from "@bludata/components";

export const GoogleCalendarSync = memo((): JSX.Element => {
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [state, setState] = useState("");
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { url } = JSON.parse(sessionStorage.getItem("sessionData") ?? "{}");

  axios.defaults.baseURL = url ? url : process.env.REACT_APP_API_URL;

  const googleCallbackHref = () => {
    return new Promise<string>((resolve, reject) => {
      setLoading(true);
      const urlString = window.location.href;
      var url = new URL(urlString);
      setState(url.searchParams.get("state")!);
      axios
        .post(`/api/GoogleCalendarUserSync/callback`, {
          state: url.searchParams.get("state"),
          code: url.searchParams.get("code"),
        })
        .then((res: any) => {
          setLoading(false);
          resolve(res.data.data);
        })
        .catch((error) => {
          setLoading(false);
          reject(error.response.data);
        });
    });
  };
  const calendars = () => {
    return new Promise<any>((resolve, reject) => {
      const urlString = window.location.href;
      var url = new URL(urlString);
      setLoading(true);
      axios
        .get(
          `/api/GoogleCalendarUserSync/calendars?token=${url.searchParams.get(
            "state"
          )}`
        )
        .then((res: any) => {
          setLoading(false);
          resolve(res.data.data);
        })
        .catch((error) => {
          setLoading(false);
          reject(error.response.data);
        });
    });
  };
  useEffect(() => {
    googleCallbackHref().then((asd: any) => {
      calendars().then((res) => {
        setRows(res);
      });
    });
  }, []);

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="google-calendar-sync"
    >
      <BluLoader open={loading} />
      <div className="background-layer"></div>
      {complete ? (
        <Paper
          elevation={1}
          className="calendar-content-box"
          sx={{
            zIndex: 10,
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <Stack
            flexDirection="column"
            alignItems="center"
            sx={{
              position: "absolute",
              top: "20px",
              left: "20px",
              right: "20px",
              bottom: "20px",
            }}
          >
            <CheckIcon color="success" sx={{ fontSize: "9rem" }} />
            <Typography sx={{ marginTop: "25px", mb: 1 }} variant="h6">
              Procedura completata
            </Typography>

            <Typography
              sx={{ marginTop: "25px", mb: 1, textAlign: "center" }}
              variant="body1"
            >
              La sincronizzazione degli appuntamenti è iniziata. Potrebbe essere
              necessario attendere alcuni minuti.
            </Typography>
          </Stack>
        </Paper>
      ) : (
        <Paper
          elevation={1}
          className="calendar-content-box"
          sx={{
            zIndex: 10,
            backgroundColor: "white",
            position: "relative",
          }}
        >
          <Stack
            flexDirection="column"
            sx={{
              position: "absolute",
              top: "20px",
              left: "20px",
              right: "20px",
              bottom: "20px",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h5">
              Console Blu Booking
            </Typography>
            <Typography sx={{ marginTop: "25px", mb: 1 }} variant="body1">
              Seleziona un calendario da associare.
            </Typography>
            <DataGridPremium
              selectionModel={selectionModel}
              onSelectionModelChange={(ids) => {
                if (selectionModel[0] === ids[0]) {
                  setSelectionModel([]);
                } else {
                  setSelectionModel(ids);
                }
              }}
              sx={{
                "& .MuiDataGrid-cell": {
                  outline: "none!important",
                },
              }}
              rows={rows}
              getRowId={(x: any) => {
                return x.googleCalendarId;
              }}
              columns={[
                {
                  flex: 1,
                  field: "name",
                  headerName: "Calendario",
                  editable: false,
                  disableColumnMenu: true,
                  sortable: false,
                  headerAlign: "left",
                  align: "left",
                },
              ]}
              components={{
                NoRowsOverlay: () => {
                  return <GridOverlay></GridOverlay>;
                },
                Footer: () => {
                  return (
                    <GridFooterContainer>
                      <Box flex={1} />
                      <Button
                        disabled={!selectionModel.length}
                        sx={{ mr: 1 }}
                        startIcon={<SyncIcon />}
                        onClick={() => {
                          const setCalendars = () => {
                            return new Promise<any>((resolve, reject) => {
                              setLoading(true);
                              axios
                                .post(
                                  `/api/GoogleCalendarUserSync/calendars?token=${state}&googleCalendarId=${selectionModel[0]}`
                                )
                                .then((res: any) => {
                                  setComplete(true);
                                  setLoading(false);
                                  resolve(res.data.data);
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  reject(error.response.data);
                                });
                            });
                          };

                          setCalendars().then((res) => {
                            return res;
                          });
                        }}
                      >
                        Sync
                      </Button>
                    </GridFooterContainer>
                  );
                },
              }}
            />
          </Stack>
        </Paper>
      )}
      <Stack
        className="my-footer"
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="body1" sx={{ marginRight: "10px" }}>
          &#169; {new Date().getFullYear()} Bludata Informatica S.r.l.
        </Typography>
        <img
          style={{
            marginRight: "10px",
            width: "auto",
          }}
          src="https://focus.bludata.com/logo_bludata_50.png"
          alt="Logo Bludata Informatica S.r.l."
        />
      </Stack>
    </Stack>
  );
});
