import { NetworkMode, QueryClient } from "@tanstack/react-query";

const defaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: false,
    networkMode: "always" as NetworkMode,
  },
};

export const queryClient = new QueryClient({
  defaultOptions,
});

/*
const defaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 86400000,
    cacheTime: 86400000,
    staleTime: 86400000,
    retry: false,

    networkMode: "always" as NetworkMode,
    // refetchOnMount: false
  },
};

*/
