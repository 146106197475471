import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography
  } from "@mui/material"
  import React, { FC, ReactElement, useCallback } from "react"
  import WarningAmberIcon from "@mui/icons-material/WarningAmber"
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
  import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
  
  interface DialogProps {
    show: boolean
    handleClose: () => void
    text: string | ReactElement
    title?: string
    handleConfirm?: () => void
    maxWidth?: "sm" | "md" | "lg"
    type?: "warning" | "error" | "info"
    confirmButton?: string
    cancelButton?: string
  }
  
  const iconDimensions = { fontSize: "80px", mb: "10px" }
  
  const BluAlert: FC<DialogProps> = React.memo(
    (props) => {
      const { handleClose, type } = props
      const handleCloseTap = useCallback(() => {
        handleClose()
      }, [handleClose])
  
      let testo: string | ReactElement | ReactElement[] = props.text
  
      if (typeof props.text === "string") {
        testo = props.text
          .split("\n")
          .map((item, i) => <Typography key={i}>{item}</Typography>)
      }
  
      return (
        <Dialog
          open={props.show}
          fullWidth
          fullScreen={false}
          maxWidth={!!props.maxWidth ? props.maxWidth : "xs"}
        >
          <DialogTitle
            style={{
              backgroundColor: "var(--background-color)",
              color: "var(--color)"
            }}
          >
            {!!type ? (
              <Stack flexDirection="column" alignItems="center">
                {type === "warning" && (
                  <WarningAmberIcon color="warning" sx={iconDimensions} />
                )}
                {type === "info" && (
                  <InfoOutlinedIcon color="primary" sx={iconDimensions} />
                )}
                {type === "error" && (
                  <ErrorOutlineOutlinedIcon color="error" sx={iconDimensions} />
                )}
                {props.title ? props.title : "Attenzione"}
              </Stack>
            ) : props.title && (
              <div>{props.title ? props.title : "Attenzione"}</div>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="div" sx={{ color: "var(--color)" }}>
              {testo}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ justifyContent: "center" }}
              variant="outlined"
              onClick={handleCloseTap}
            >
              {props.handleConfirm ? props.cancelButton?props.cancelButton:"No" : "Chiudi"}
            </Button>
            {props.handleConfirm && (
              <Button
                sx={{ justifyContent: "center" }}
                variant="outlined"
                onClick={props.handleConfirm}
              >
                {props.confirmButton?props.confirmButton:"Sì"}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )
    },
    (oldProps, newProps) => {
      return (
        oldProps.show === newProps.show &&
        oldProps.text === newProps.text &&
        oldProps.title === newProps.title &&
        oldProps.handleClose === newProps.handleClose &&
        oldProps.handleConfirm === newProps.handleConfirm &&
        oldProps.maxWidth === newProps.maxWidth &&
        oldProps.type === newProps.type
      )
    }
  )
  export default BluAlert
  