export const content = `
Ai sensi del Regolamento (UE) 2016/679, (d’ora in poi “Regolamento”) questa pagina descrive le modalità di trattamento dei dati personali degli utenti che utilizzano la web application https://console.ilmioottico.it/


## Titolare del trattamento e Responsabile della Protezione dei Dati 
Il Titolare del trattamento è Bludata Informatica S.r.l., con sede a Breda di Piave (TV), Via delle Industrie, 10. Tel. 0422445442, Fax. 0422699318, e-mail: info@bludata.com PEC: bludata@legalmail.it. Il Responsabile della Protezione dei Dati (RPD) è raggiungibile ai recapiti del Titolare del trattamento e al seguente indirizzo email: dpo@bludata.com.


## Tipi di dati trattati e finalità del trattamento 
### Dati di navigazione
I sistemi informatici e le procedure software preposte al funzionamento di questa applicazione web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.&nbsp;   
Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.&nbsp;  
In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.&nbsp;   
Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito: salva questa eventualità, allo stato i dati sui contatti web non persistono per più di sette giorni.


### Cookies 
I cookies sono piccoli file di testo contenenti una certa quantità di informazioni scambiate tra un sito internet ed il terminale dell’utente (solitamente il browser). &nbsp;  
Essi sono principalmente utilizzati con lo scopo di far funzionare i siti web e farli operare in modo più efficiente, nonché al fine di fornire informazioni ai proprietari del sito stesso.&nbsp;  
I cookies possono essere sia di sessione che persistenti. I cookies di sessione rimangono memorizzati nel terminale per un breve periodo di tempo e sono eliminati non appena l’utente chiude il browser.&nbsp;  
Il loro utilizzo è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire l’esplorazione sicura ed efficiente del sito.&nbsp;  
I cookies persistenti invece rimangono archiviati nel terminale dell’utente fino ad una scadenza prestabilita. Questi, non essendo cancellati direttamente alla chiusura del browser, consentono di ricordare le scelte operate dall’utente sul proprio sito nonché di raccogliere informazioni circa le pagine del sito visitate dall’utente, la frequenza con la quale il sito viene visitato e per identificare il tracciato di navigazione degli utenti, al fine di migliorare l’esperienza sul presente sito. &nbsp;  
I cookies, di sessione o persistenti, possono essere infine di Prima parte o di Terza parte a seconda che il soggetto che installa i cookies sul terminale dell’utente sia lo stesso gestore del sito che l’utente sta visitando (si parlerà allora di cookies di prima parte) oppure un soggetto diverso (si parlerà allora di cookies di terza parte).


### Cookie utilizzati in questo sito
| NOME COOKIE                  | PERSISTENZA | FINALITA' COOKIE                                                                                                                  |
| ---------------------------- | ----------- | --------------------------------------------------------------------------------------------------------------------------------- |
| BluBookingSoftware           | 12 ore      | tipo di software nel quale viene utilizzato blu booking                                                                           |
| BluBookingUserPhoto          | 12 ore      | foto dell'utente                                                                                       |
| BluBookingUserAdmin          | 12 ore      | privilegi dell'utente                                                           |
| BluBookingCompanyColor       | 12 ore      | colore nell'azienda |
| BluBookingpointOfSaleId      | 12 ore      | punto vendita dell'utente                                                       |
| BluBookingSocketChannel      | 12 ore      | identificativo del canale per la gestione delle notifiche                                                                                               |
| BluBookingRefreshToken       | 48 ore      | token di refresh                                                                                               |
| BluBookingToken              | 12 ore      | token                                                                                               |
| BluBookingCalendar           | 1 anno      | l'ultimo calendario utilizzato                                                                                               |


## Base giuridica del trattamento
Il Titolare tratta dati personali relativi all’utente in caso sussista una delle seguenti condizioni: 
* il trattamento è necessario all’esecuzione di un contratto con l’utente e/o all’esecuzione di misure precontrattuali; 
* il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare; 
* il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio di pubblici poteri di cui è investito il titolare; 
* il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi; 
* l’utente ha prestato il consenso per una o più finalità specifiche. 
È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto. 


### Modalità del trattamento, destinatari e tempi di conservazione
I dati sono trattati tramite mezzi elettronici ed automatizzati da personale autorizzato al trattamento dei dati personali (per esempio personale degli uffici Marketing, Amministrazione, Assistenza post-vendita, Amministratori di sistema ecc.) oppure da soggetti che agiscono in veste di Responsabili del trattamento ai sensi dell’art. 28 del Regolamento. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento. &nbsp;  
Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. I dati raccolti saranno conservati – per ciascuna tipologia di dati trattati – esclusivamente per il tempo necessario ad adempiere alle specifiche finalità indicate nelle specifiche informative di sintesi visualizzate nelle pagine del sito e predisposte per particolari servizi. 


## Comunicazione e diffusione dei dati
I dati raccolti potranno essere trasferiti o comunicati ad altre società per attività strettamente connesse e strumentali all’operatività del servizio, come la gestione del sistema informatico. &nbsp;  
I dati personali forniti dagli utenti che inoltrano richieste di invio di materiale informativo sui software e servizi del Titolare del trattamento sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario (es.: società che forniscono servizi di spedizione). &nbsp;  
Al di fuori di questi casi, i dati personali non saranno comunicati salvo previsione contrattuale o di legge, ovvero salvo specifico consenso richiesto all’interessato.
In questo senso, i dati personali potrebbero essere trasmessi a terze parti, ma solo ed esclusivamente nel caso in cui: 
1. vi sia consenso esplicito a comunicare i dati a terze parti; 
2. vi sia la necessità di condividere con terzi le informazioni al fine di prestare il servizio richiesto; 
3. ciò sia necessario per adempiere a richieste dell’Autorità Giudiziaria o di Pubblica Sicurezza. 
Nessun dato derivante dal servizio web viene diffuso. 

### Integrazione con Google Calendar
Blu Booking consente agli utenti di sincronizzare i dati degli appuntamenti con Google Calendar. Quando la sincronizzazione è attivata, le seguenti informazioni sugli appuntamenti verranno condivise con Google Calendar: nome del cliente, data/ora di inizio, durata e tipo di servizio. &nbsp;
Tutti gli appuntamenti sincronizzati da Google Calendar a Blu Booking verranno resi anonimi e solo la data/ora di inizio e la durata verranno archiviate per motivi di disponibilità. &nbsp;
#### Dichiarazione sui requisiti di utilizzo limitato
L'uso e il trasferimento da parte di Blu Booking a qualsiasi altra app delle informazioni ricevute dalle API di Google saranno conformi alla Google API Services User Data Policy, inclusi i requisiti di utilizzo limitato. &nbsp;

## Diritti degli interessati 
La normativa a protezione dei dati personali prevede espressamente alcuni diritti in capo ai soggetti cui dati si riferiscono (c.d. interessato). &nbsp;  
In particolare, ai sensi degli articoli 15-22 del Regolamento (UE) 2016/679, ciascun interessato ha il diritto di conoscere l’elenco aggiornato dei Responsabili del trattamento, di ottenere la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto, l’origine, verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione. Ai sensi dei medesimi articoli, l’interessato ha il diritto di chiedere la cancellazione, la limitazione, la portabilità dei dati, revocare il consenso, nonché di opporsi, per motivi legittimi, al trattamento dei dati. &nbsp;  
Inoltre, ai sensi dell’art. 77 del Regolamento, qualora l’interessato ritenga che il trattamento che lo riguarda violi il Regolamento, ha il diritto di proporre reclamo a un’autorità di controllo, segnatamente nello Stato membro in cui risiede abitualmente, lavora oppure si è verificata la presunta violazione. Tale autorità, per quanto riguarda il territorio italiano, è il Garante per la protezione dei dati personali. &nbsp;  
Per l’esercizio di tali diritti, l’interessato può rivolgersi al Titolare del trattamento scrivendo a Bludata Informatica S.r.l., Via delle Industrie, 10 Breda di Piave (TV). Tel. 0422445442, Fax. 0422699318, E-mail: privacy@bludata.com PEC: bludata@legalmail.it. &nbsp;  
Il Responsabile della Protezione dei Dati (RPD) è raggiungibile ai recapiti del Titolare del trattamento e al seguente indirizzo email: dpo@bludata.com`;


