import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { FC, KeyboardEvent, memo, useCallback, useState } from "react";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import { setAuthenticated } from "../../store/root";
import { useDispatch } from "react-redux";
import { checkPassword } from "../../api/check-password";

interface BludataPasswordRequiredProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const BludataPasswordRequired: FC<BludataPasswordRequiredProps> = memo(
  (props) => {
    const dispatch = useDispatch();

    const { handleClose, handleConfirm } = props;
    const [passwordToCheck, setPasswordToCheck] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const handleCloseTap = useCallback(() => {
      setPasswordToCheck("");
      setPasswordError(false);
      handleClose();
    }, [handleClose, setPasswordError, setPasswordToCheck]);

    const onConfirmTap = useCallback(async () => {
      // @ts-ignore
      const result: boolean = await checkPassword(passwordToCheck);

      setPasswordToCheck("");

      if (result) {
        setPasswordError(false);
        dispatch(setAuthenticated(true));
        handleConfirm();
      } else {
        setPasswordError(true);
      }
    }, [passwordToCheck, dispatch, handleConfirm]);

    const onPasswordFieldKeyUp = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        const keyPressed = event.key;

        if (keyPressed === "Enter") {
          onConfirmTap();
        }
      },
      [onConfirmTap]
    );

    const onPasswordFieldChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordError(false);
        setPasswordToCheck(event.target.value);
      },
      [setPasswordError, setPasswordToCheck]
    );

    return (
      <Dialog
        open={props.show}
        fullWidth
        maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "var(--base-foreground-color)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            backgroundColor: "var(--base-foreground-color)",
            m: "0",
          }}
          variant="h5"
        >
          <LockTwoToneIcon color="primary" sx={{ fontSize: "50px" }} />
          <br />
          <Typography variant="h5" color="primary">
            Credenziali richieste
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "var(--base-foreground-color)",
          }}
        >
          <DialogContentText>
            Inserisci la tua password prima di continuare.
          </DialogContentText>
          <TextField
            label="Password"
            sx={{ mt: "10px", width: "100%" }}
            value={passwordToCheck}
            type="password"
            onChange={onPasswordFieldChange}
            helperText={passwordError ? "Password inserita non valida." : ""}
            error={passwordError}
            autoFocus
            onKeyUp={onPasswordFieldKeyUp}
            autoComplete="new-password"
          />
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "var(--base-foreground-color)",
          }}
        >
          <Button variant="outlined" onClick={handleCloseTap}>
            Annulla
          </Button>
          <Button variant="contained" onClick={onConfirmTap}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.show === newProps.show &&
      oldProps.handleClose === newProps.handleClose &&
      oldProps.handleConfirm === newProps.handleConfirm
    );
  }
);

export default BludataPasswordRequired;
